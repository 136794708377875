import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
//import Button from "@material-ui/core/Button";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

function Settings() {
  const players = {
    Browser: "browser",
    Mxplayer: "com.mxtech.videoplayer.ad",
    Vlc: "org.videolan.vlc",
    Wuffy: "co.wuffy.player"
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  var playerOptions = [
    <option key={""} value={""}>
      Please select a player
    </option>
  ];
  for (var player in players) {
    playerOptions.push(
      <option key={players[player]} value={players[player]}>
        {player}
      </option>
    );
  }
  return (
    <Dialog
      fullScreen
      open
      id={"settings"}
      onKeyDown={checkKeys}
      TransitionComponent={Transition}
    >
      <AppBar className={useStyles().appBar}>
        <Toolbar>
          <Typography variant="h6" className={useStyles().title}>
            Settings
          </Typography>
          {/* <Button color="inherit">save</Button> */}
        </Toolbar>
      </AppBar>
      <List>
        <ListItem button>
          <ListItemText
            primary="Movie Player"
            secondary={player === "" ? player : "No player selected"}
          />
          <select
            defaultValue={localStorage.getItem("player")}
            onChange={setPlayer}
            id={"selectPlayer"}
            autoFocus
          >
            {playerOptions}
          </select>
        </ListItem>
        <Divider />
      </List>
    </Dialog>
  );
}

function setPlayer() {
  var player = document.getElementById("selectPlayer").value;
  localStorage.setItem("player", player);
  console.log(localStorage.getItem("player"));
  return;
}

function checkKeys(e) {
  switch (e.keyCode) {
    case 8:
      console.log("back");
      window.location.reload();
      break;
    default:
      console.log("other");
  }
}

export default Settings;