import React from 'react';
import './App.css';

import MovieTiles from "./MovieTiles/MovieTiles.js";
import Settings from "./Settings/Settings.js";

function App() {
  return <div className="App">{drawPage()}</div>;
}

function drawPage() {
  if (localStorage.getItem("player")) {
    return <MovieTiles />;
  } else {
    return <Settings />;
  }
}

export default App;
