import React from "react";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import useAxios from "axios-hooks";

import "./styles.css";

var currentMovie = -1;
const width =
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;
const moviecols = Math.ceil(width / 300);
var isFirefox = typeof InstallTrigger !== "undefined";
var supportsTouch = "ontouchstart" in window || navigator.msMaxTouchPoints;

function MovieTiles() {
  const [{ data, loading, error }, refetch] = useAxios(
    "https://nw.klajdi.ga/files/rwflix/api.php"
  );

  const linkStyle = {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: "1",
    fontSize: "0"
  }; //Overlay movie link

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error!</p>;

  var elements = [];
  var player = localStorage.getItem("player");
  for (var sources in data) {
    for (var movies in data[sources]) {
      var movie = data[sources][movies];
      elements.push(
        <GridListTile tabIndex={0} key={movies} className={"MovieTile"}>
          <a
            className={"MovieLink"}
            href={
              player !== "browser"
                ? "intent:http://192.168.1.10" +
                  movie.dir +
                  "#Intent;package=" +
                  localStorage.getItem("player") +
                  ";end"
                : "http://192.168.1.10" + movie.dir
            }
            style={linkStyle}
          >
            Test
          </a>
          <img src={movie.poster} alt={movie.name} style={{ width: "100%" }} />
          <GridListTileBar
            className={"MovieTileBar"}
            title={
              <div className={"MovieHeader"}>
                {movie.name} {movie.year}
              </div>
            }
            subtitle={
              <React.Fragment>
                Rating {movie.imdbRating}
                <p>{movie.genre}</p>
              </React.Fragment>
            }
          />
        </GridListTile>
      );
    }
  }
  return (
    <GridList cellHeight={"auto"} cols={moviecols}>
      <button id="refetch" onClick={refetch} style={{ display: "none" }}>
        Refetch Movies
      </button>
      {elements}
    </GridList>
  );
}

function selectMovie(number, open = false) {
  var movieLinks = document.getElementsByClassName("MovieLink");

  if (number < 0) {
    number = currentMovie = 0;
  } else if (number > movieLinks.length - 1) {
    number = movieLinks.length - 1;
    currentMovie = movieLinks.length - 1;
  }
  movieLinks[number].focus();
  //movieLinks[number].scrollIntoView();
  console.log(number);
}

document.onkeydown = function(e) {
  switch (e.keyCode) {
    case 37:
      currentMovie--;
      selectMovie(currentMovie);
      //console.log("left");
      break;
    case 38:
      currentMovie += -moviecols;
      selectMovie(currentMovie);
      //console.log("up");
      break;
    case 39:
      currentMovie++;
      selectMovie(currentMovie);
      //console.log("right");
      break;
    case 40:
      currentMovie += moviecols;
      selectMovie(currentMovie);
      //console.log("down");
      break;
    case 13:
      selectMovie(currentMovie, true);
      //console.log("Enter");
      break;
    case 48:
      document.getElementById("refetch").click();
      currentMovie = 0;
      break;
    default:
      console.log("other");
  }
};

export default MovieTiles;